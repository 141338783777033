<template>
  <div class="main">
    <span class="title">Welcome</span>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 20px;
}
</style>
